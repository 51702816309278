<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.searchString"
                dense
                outlined
                hide-details
                placeholder="Zugang suchen"
                class="channel-list-search me-3"
              ></v-text-field>
              <v-select
                v-model="filter.angabeAktiv"
                :items="filterAngabeAktivOptions"
                item-text="description"
                item-value="angabeAktiv"
                single-line
                outlined
                dense
                hide-details
                clearable
                placeholder="Aktiv"
                class="channel-list-angabeAktiv me-3"
              ></v-select>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="channel-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div class="d-flex align-center pb-5">
              <v-btn
                color="secondary"
                outlined
                class="me-3"
                @click="editAccess()"
              >
                <v-icon size="30" class="pr-2">{{icons.mdiPencilOutline }}</v-icon>
                <span>Zugang erstellen</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="listAccesses"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.searchString"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>
                <v-card-text
                  class="text-center"
                >
                  Möchten Sie <strong>&nbsp;{{ deleteItem.benutzername }}&nbsp;</strong> wirklich löschen?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteAccessConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="dialogEdit"
              width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editItem.benutzername"
                          label="Benutzername"
                          persistent-clear
                          dense
                          outlined
                          :rules="[usernameRules]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12">
                        <v-text-field
                          v-model="editItem.passwort"
                          v-if="editItem.idNipApiZugang === 0"
                          hint="Das Passwort muss mindestens 16 Zeichen lang sein und Klein- und Großbuchstaben, Zahlen und Sonderzeichen besitzen."
                          persistent-hint
                          persistent-clear
                          clearable
                          label="Passwort"
                          dense
                          outlined
                          :rules="[passwordRules]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :disabled="editValidationError"
                    @click="editAccessConfirm()"
                  >
                    Speichern
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeEdit()"
                  >
                    Abbrechen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </template>
          <template #[`item.idNipApiZugang`]="{item}">
            #{{ item.idNipApiZugang }}
          </template>
          <template #[`item.angabeAktiv`]="{item}">
            <v-chip
              @click="setAccessState( item.idNipApiZugang, !item.angabeAktiv )"
              small
              :color="item.angabeAktiv == 1 ? 'success':'secondary'"
              :class="`v-chip-light-bg ${item.angabeAktiv == 1 ? 'success':'secondary'}--text font-weight-semibold`"
            >
              {{ item.angabeAktiv == 1 ? 'Aktiv':'Inaktiv' }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{item}">
            <div class="">
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="editAccess( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteAccess( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiFilePdf,
  mdiPlus,
  mdiAccountCog
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";

export default {
  name: 'apiaccesses-list',
  components: {
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const ratingsOptions = {
      statTitle: 'API-Zugänge',
      statistics: '',
      change: '',
      chipText: 'Gesamt',
      chipColor: 'error',
      icon:mdiAccountCog
    }

    return {
      vm,
      router,
      ratingsOptions
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiAccountCog,
        mdiPlus,
      },
      filter: { searchString: '', angabeAktiv: '' },
      loading: false,
      totalAmountList: 0,
      filterAngabeAktivOptions: [
        { angabeAktiv: 1, description: 'aktiv' },
        { angabeAktiv: 0, description: 'inaktiv' },
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipApiZugang'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idNipApiZugang',
        },
        {text: 'Benutzername', value: 'benutzername'},
        {text: 'Aktiv', value: 'angabeAktiv'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listAccesses: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNipApiZugang: 0,
        benutzername: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNipApiZugang: 0,
        benutzername: '',
        passwort: '',
      },
      defaultItem: {
        idNipApiZugang: 0,
        benutzername: '',
        passwort: '',
      },
      editValidationError : true,

    }
  },
  mounted() {
    this.fetchAccesses()
  },
  watch: {
    options: {
      handler() {
        this.fetchAccesses()
      },
    },
    filter: {
      handler() {
        this.fetchAccesses()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Zugang erstellen' : 'Zugang bearbeiten'
    },
  },
  methods: {
    fetchAccesses() {
      this.loading = true
      axios.post('api/verwaltung/', {
        aktion: 'showOverviewApiAccesses',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listAccesses = response.data.apiaccesses || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    setAccessState(idNipApiZugang, state) {
      axios.post('api/verwaltung/', {
        aktion: 'changeAccessState',
        idNipApiZugang: idNipApiZugang,
        newState: state,
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchAccesses()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteAccess (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteAccessConfirm () {
      axios.post('api/verwaltung/', {
        aktion: 'deleteApiAccess',
        mainData :{idNipApiZugang: this.deleteItem.idNipApiZugang},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchAccesses()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },

    editAccess (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listAccesses.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    usernameRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 8) {
        return 'Der Benutzername muss mindetens 8 Zeichen besitzen.';
      }
      else if((v || '').length > 64) {
        return 'Der Benutzername kann maximal 64 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },
    passwordRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if(!(v || '').match(/[$@#&!.-_]+/)) {
        return 'Bitte nutzen Sie mindestens ein Sonderzeichen.';
      }
      else if(!(v || '').match(/[0-9]+/)) {
        return 'Bitte nutzen Sie mindestens eine Ziffer.';
      }
      else if(!(v || '').match(/[A-Z]+/)) {
        return 'Bitte nutzen Sie mindestens ein Großbuchstaben.';
      }
      else if(!(v || '').match(/[a-z]+/)) {
        return 'Bitte nutzen Sie mindestens ein Kleinbuchstaben.';
      }
      else if((v || '').length < 16) {
        return 'Das Passwort muss mindetens 16 Zeichen besitzen.';
      }
      else if((v || '').length > 64) {
        return 'Das Passwort kann maximal 64 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },

    editAccessConfirm () {
      axios.post('api/verwaltung/', {
        aktion: this.editIndex === -1 ? 'createApiAccessCheck' : 'editApiAccessCheck',
        mainData: this.editItem,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchAccesses()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField !== "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal!important;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
}
</style>
